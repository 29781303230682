import { useState, useEffect } from 'react'
import { cx } from '@/utils/strings'

export default function CheckBox({ label, defaultChecked = false, value = undefined, onUpdate = undefined, ...other }) {
  const [isChecked, setIsChecked] = useState(defaultChecked || value)

  useEffect(() => {
    if (value !== undefined && value !== isChecked) setIsChecked(value)
  }, [value])

  return (
    <label
      className={cx('group flex items-center', other.disabled ? 'text-gray-500 cursor-not-allowed' : 'cursor-pointer')}
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={() => {
          setIsChecked((prev) => {
            if (onUpdate) onUpdate(!prev)
            return !prev
          })
        }}
        className={cx(
          'w-5 h-5 mr-2 transition-all ',
          other.disabled
            ? 'cursor-not-allowed'
            : 'cursor-pointer focus:outline-none focus:ring-2 focus:ring-primaryBlue group-hover:drop-shadow-[0 0 5px #175ddc]'
        )}
        {...other}
      />
      {label}
    </label>
  )
}
